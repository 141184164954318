import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from '@/components/PrivateRoute'
import Belture from '@/views/Belture'
import StudentHome from '@/views/Home/StudentHome'
import Peers from '@/views/Peers'
import Project from '@/views/Projects/Project'
import ProjectDefinition from '@/views/Projects/ProjectDefinition'
import Projects from '@/views/Projects/Projects'
import User from '@/views/Settings/User'
import Skill from '@/views/Skill'
import Skills from '@/views/Skills'
import Student from '@/views/Student'

const StudentRoutes = () => <Switch>
  <PrivateRoute exact path="/">
    <StudentHome />
  </PrivateRoute>

  <PrivateRoute exact path="/projects">
    <Projects />
  </PrivateRoute>

  <PrivateRoute exact path="/project-definitions/:id">
    <ProjectDefinition />
  </PrivateRoute>

  <PrivateRoute exact path="/projects/:id">
    <Project />
  </PrivateRoute>

  <PrivateRoute exact path="/skills">
    <Skills />
  </PrivateRoute>

  <PrivateRoute exact path="/skills/:slug">
    <Skill />
  </PrivateRoute>

  <PrivateRoute exact path="/peers">
    <Peers />
  </PrivateRoute>

  <PrivateRoute exact path="/peers/:id">
    <Student />
  </PrivateRoute>

  <PrivateRoute exact path="/settings/user">
    <User />
  </PrivateRoute>

  <PrivateRoute exact path="/belture">
    <Belture />
  </PrivateRoute>

  <Route path="*">
    <Redirect to="/" />
  </Route>
</Switch>

export default StudentRoutes
